<template>
  <div class="authorization page-container-table">
    <el-tabs v-model="tabsActive" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabsList"
        :key="index"
        :label="item.label"
        :name="item.name+'&'"
      />
    </el-tabs>
    <div class="authorizationBox">
      <!-- 搜索筛选 -->
      <FormSearch
        :form-inline="formInline"
        :form-item-arr="formItemArr"
        :getdata="getdata"
        class="form_Search"
        @resetFormInline="resetFormInline"
      />
      <!-- 表格  分页 -->
      <div class="page-container-table">
        <Table
          :operation-button-width="236"
          :operation-button="operationButton"
          :item-data="itemData"
          :list-data="listData"
        />
        <!-- 分页 -->
        <Pagination
          :params="formInline"
          :total="total"
          :in-article="listData.length"
          :get-data-list="getdata"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { creditManagementList } from '@/api/orderFinancing'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      tabsActive: '&',
      tabsList: [
        { label: '全部', name: '' },
        { label: '处理中', name: 3 },
        { label: '待生效', name: 0 },
        { label: '已生效', name: 1 },
        { label: '已失效', name: 2 },
        { label: '已驳回', name: 4 }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      formItemArr: [
        { type: 'input', label: '授信单号', value: 'creditNo', width: '180' },
        {
          type: 'input',
          label: '申请方',
          value: 'applicationCmpName',
          width: '180'
        },
        { type: 'input', label: '资金方', value: 'funderName', width: '180' }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.addRow },
        { withParameters: 'audit', val: 'audit', bType: 'primary', label: '审核', handleEvent: this.addRow }
      ],
      itemData: [
        { label: '授信单号', prop: 'creditNo', width: '160' },
        { label: '申请方', prop: 'applicationCmpName', width: '160' },
        { label: '资金方', prop: 'funderName', width: '160' },
        { label: '授信额度（元）', prop: 'creditLine', width: '160' },
        { label: '已用额度（元）', prop: 'usedCreditLine', width: '160' },
        { label: '冻结额度（元）', prop: 'frozenCreditLine', width: '160' },
        { label: '可用额度（元）', prop: 'usableCreditLine', width: '160' },
        { label: '额度到期日', prop: 'creditTimeEnd', width: '160' },
        { label: '申请时间', prop: 'createTime', width: '160' },
        { label: '状态', prop: 'creditStatus', width: '160', child: this.$store.getters.getDictionaryItem('SCF_CREDIT_AUDIT_STATUS') }
      ],
      listData: []
    }
  },
  methods: {
    // 详情
    addRow(row, audit) {
      this.$store.commit('setResourceByTaskObj', { ...row })
      this.$router.push({
        path: '/orderFinancing/creditManagementDetails',
        query: { id: row.id, audit }
      })
    },
    // 点击重置按钮
    resetFormInline() {
      this.tabsActive = '&'
    },
    // 点击tab
    handleClick() {
      this.formInline.status = this.tabsActive.replace('&', '')
      this.getdata()
    },
    // 获取数据
    getdata(type) {
      //  获取授信管理列表
      if (!type) {
        this.formInline.pageNum = 1
      }
      creditManagementList(this.formInline, res => {
        res.data.pageData.forEach((item, index) => {
          if (item.activiteId && item.currentNodeId) {
            switch (item.auditStatus) {
              case '-1':
                item.audit = false
                break
              case '99':
                item.audit = false
                break
              default:
                item.audit = true
                break
            }
          } else {
            item.audit = false
          }

          // switch (item.status) {
          //   case 0:
          //     item.statusName = '待生效'
          //     break
          //   case 1:
          //     item.statusName = '已生效'
          //     break
          //   case 2:
          //     item.statusName = '已失效'
          //     break
          //   case 3:
          //     item.statusName = '处理中'
          //     break
          //   case 4:
          //     item.statusName = '已驳回'
          //     break
          //   default:
          //     break
          // }
        })

        this.listData = [...res.data.pageData]
        this.total = res.data.total
        this.formInline.pageSize = res.data.pageSize
        this.formInline.pageNum = res.data.pageNum
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container-table {
  box-shadow: none !important;

  .demo-tabs {
    margin-bottom: 10px;
  }

  .form-search {
    box-shadow: none !important;
  }
}
</style>
